import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  GlobalStyle,
  AppContainer,
  Header,
  Title,
  Content,
  CandidateList,
  CandidateItem,
  SubmitButton,
  RankNumber,
  WelcomeMessage,
  ExplanationText,
  ThankYouMessage,
  ThankYouContent,
  InfoText,
  LinkContainer,
  LinkLabel,
  LinkTextBox,
  ButtonContainer,
  CopyButton
} from './styles';
import styled from 'styled-components';

const apiUrl = '/api';

// New styled components for results
const ResultsSection = styled.div`
  margin-bottom: 2rem;
`;

const ResultsTitle = styled.h3`
  font-size: 1.4rem;
  color: #3498db;
  margin-bottom: 1rem;
`;

const WinnerTitle = styled.h3`
  font-size: 2rem;
  color: #2ecc71;
  margin-bottom: 1rem;
`;

const ResultsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ResultsItem = styled.li`
  background-color: #3c3c3c;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
`;

const RoundTitle = styled.h4`
  font-size: 1.2rem;
  color: #2ecc71;
  margin-bottom: 0.5rem;
`;

const EliminatedText = styled.p`
  color: #e74c3c;
  font-weight: bold;
`;

const WinnerText = styled.p`
  color: #f39c12;
  font-weight: bold;
`;


const ResultBar = styled.div`
  background-color: #4a4a4a;
  height: 100%;
  width: ${props => props.width}%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: width 0.5s ease-in-out;
`;

const FinalRankingList = styled.ol`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const FinalRankingItem = styled.li`
  background-color: #3c3c3c;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const RankingContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
`;

const RankEmoji = styled.span`
  font-size: 1.5rem;
  margin-right: 0.5rem;
  min-width: 30px;
`;

const CandidateName = styled.span`
  font-weight: bold;
  margin-right: 0.5rem;
`;

const AverageRanking = styled.span`
  margin-left: auto;
  font-style: italic;
`;

const ShareLinkContainer = styled(LinkContainer)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const CopyFeedback = styled.span`
  color: #2ecc71;
  margin-right: 10px;
  font-size: 0.9rem;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;

const FirstPreferenceList = styled(ResultsList)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const FirstPreferenceItem = styled(ResultsItem)`
  flex: 1;
  min-width: 150px;
`;

const RoundInfo = styled.div`
  background-color: #3c3c3c;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

function Poll() {
  const [candidates, setCandidates] = useState([]);
  const [lastDraggedId, setLastDraggedId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pollTitle, setPollTitle] = useState('');
  const { pollId } = useParams();
  const [poll, setPoll] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [results, setResults] = useState(null);
  const [copiedLink, setCopiedLink] = useState(false);

  useEffect(() => {
    fetchPollData();
    checkIfVoted();
  }, [pollId]);

  const fetchPollData = async () => {
    try {
      const response = await fetch(`${apiUrl}/polls/${pollId}`);
      if (!response.ok) {
        throw new Error('Poll not found');
      }
      const poll = await response.json();
      const shuffledCandidates = shuffleArray(poll.candidates);

      setCandidates(shuffledCandidates.map((name, index) => ({ id: `candidate${index + 1}`, name })));
      setPollTitle(poll.title);
      setPoll(poll);
      setIsLoading(false);

      if (!poll.is_open) {
        fetchResults();
      }
    } catch (error) {
      console.error('Error fetching poll data:', error);
      setError('Failed to load poll data. Please try again.');
      setIsLoading(false);
    }
  };

  // Function to shuffle an array (Fisher-Yates algorithm)
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const fetchResults = async () => {
    try {
      const response = await fetch(`${apiUrl}/polls/${pollId}/results`);
      if (!response.ok) {
        throw new Error('Failed to fetch results');
      }
      const resultsData = await response.json();
      setResults(resultsData);
    } catch (error) {
      console.error('Error fetching results:', error);
      setError('Failed to load poll results. Please try again.');
    }
  };

  const checkIfVoted = () => {
    const votedPolls = JSON.parse(localStorage.getItem('votedPolls')) || [];
    setHasVoted(votedPolls.includes(pollId));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(candidates);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCandidates(items);
    setLastDraggedId(result.draggableId);
  };

  const handleSubmit = async () => {
    if (!poll.is_open) {
      return;
    }

    if (hasVoted) {
      return;
    }

    try {
      const vote = {};
      candidates.forEach((candidate, index) => {
        vote[candidate.name] = candidates.length - index;
      });

      const response = await fetch(`${apiUrl}/polls/${pollId}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ vote }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit vote');
      }

      // Update localStorage to mark this poll as voted
      const votedPolls = JSON.parse(localStorage.getItem('votedPolls')) || [];
      votedPolls.push(pollId);
      localStorage.setItem('votedPolls', JSON.stringify(votedPolls));

      setHasVoted(true);
      // Optionally, refresh the poll data to get the updated status
      fetchPollData();
    } catch (error) {
      console.error('Error submitting vote:', error);
      alert('Failed to submit vote. Please try again.');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedLink(true);
      setTimeout(() => setCopiedLink(false), 2000); // Reset after 2 seconds
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const renderResults = () => {
    if (!results) return null;
  
    return (
      <ResultsSection>
        <WelcomeMessage>Poll Results</WelcomeMessage>
        <ExplanationText>This poll has been closed. Here are the results:</ExplanationText>
        
        <WinnerTitle>Winner: {results.winner}</WinnerTitle>
        <ExplanationText>Total Votes: {results.total_votes}</ExplanationText>
        
        <ResultsTitle>Final Ranking:</ResultsTitle>
        <FinalRankingList>
          {results.final_ranking.map((candidate, index) => (
            <FinalRankingItem key={candidate.candidate}>
              <ResultBar width={(candidate.votes / (results.total_votes * 1.5)) * 100} />
              <RankingContent>
                <CandidateName>{candidate.candidate}</CandidateName>
                <AverageRanking>
                  Pairwise Victories: {candidate.votes}
                </AverageRanking>
              </RankingContent>
            </FinalRankingItem>
          ))}
        </FinalRankingList>
        
        <ResultsTitle>First Preference Votes:</ResultsTitle>
        <FirstPreferenceList>
          {Object.entries(results.first_preference_votes).map(([candidate, votes]) => (
            <FirstPreferenceItem key={candidate}>
              {candidate}: {votes} votes
            </FirstPreferenceItem>
          ))}
        </FirstPreferenceList>
      </ResultsSection>
    );
  };

  if (isLoading) return (
    <AppContainer>
      <Header>
        <Title>Ranked Choice Voting</Title>
      </Header>
      <Content>Loading...</Content>
    </AppContainer>
  );

  if (error) return (
    <AppContainer>
      <Header>
        <Title>Ranked Choice Voting</Title>
      </Header>
      <Content>{error}</Content>
    </AppContainer>
  );

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Header>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Title>Ranked Choice Voting</Title>
          </Link>
        </Header>
        <Content>
          <h2>{pollTitle}</h2>
          {poll && !poll.is_open ? (
            renderResults()
          ) : hasVoted ? (
            <ThankYouContent>
              <ThankYouMessage>Thank You for Voting!</ThankYouMessage>
              <InfoText>
                Your vote has been successfully recorded for this poll.
              </InfoText>
              <InfoText>
                The results will be available on this page once the voting has concluded.
              </InfoText>
              <InfoText>
                Feel free to check back later to see the final results!
              </InfoText>
            </ThankYouContent>
          ) : (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <CandidateList {...provided.droppableProps} ref={provided.innerRef}>
                      {candidates.map((candidate, index) => (
                        <Draggable key={candidate.id} draggableId={candidate.id} index={index}>
                          {(provided, snapshot) => (
                            <CandidateItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isSelected={snapshot.isDragging || candidate.id === lastDraggedId}
                            >
                              <RankNumber>{index + 1}</RankNumber>
                              {candidate.name}
                            </CandidateItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </CandidateList>
                  )}
                </Droppable>
              </DragDropContext>
              <SubmitButton onClick={handleSubmit} disabled={hasVoted}>
                Submit Vote
              </SubmitButton>
              <ShareLinkContainer>
                <LinkLabel>Share this poll:</LinkLabel>
                <LinkTextBox 
                  type="text" 
                  value={`${window.location.origin}/poll/${pollId}`} 
                  readOnly
                />
                <ButtonContainer>
                  <CopyFeedback visible={copiedLink}>Copied!</CopyFeedback>
                  <CopyButton onClick={() => copyToClipboard(`${window.location.origin}/poll/${pollId}`)}>
                    Copy
                  </CopyButton>
                </ButtonContainer>
              </ShareLinkContainer>
            </>
          )}
        </Content>
      </AppContainer>
    </>
  );
}

export default Poll;